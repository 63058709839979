
import "./CrossIcon.css"

function CrossIcon(props)
{

    return(
        <a className='cross-icon' href="#/">
            <div className='cross-line up-bar'/>
            <div className='cross-line down-bar'/>
        </a>
    );
}

export default CrossIcon;