import ImagePageLink from './ImagePageLink'

import './ImageLinkArray.css'

function ImageLinkArray(props)
{
    //var linkClasses = getLinkClasses(props.page_links,props.links_per_page);
    return(
        <ul id={props.id?props.id:'links'} className='image-link-array'>
            {props.page_links.map((link,index)=>
                <li key={index} className={link.size?"link-element link-"+link.size:""}>
                    <ImagePageLink 
                        img_src={link.image.meta.download_url} 
                        hvr_img_src={link.hover_image? link.hover_image.meta.download_url : ""}
                        link_url={link.link_url}
                        title={link.title}
		    	genre={link.genre}
                    />
                </li>
            )}
        </ul>
    );
}

export default ImageLinkArray;
