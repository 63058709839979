
import './Spacer.css'

function Spacer(props)
{
    var style = {height:props.height};
    return(
        <div className="spacer" style={style}>
            {props.img_src?<img src={props.img_src} className="spacer-img"/>:<div className='empty-spacer'/>}
        </div>
    );
}

export default Spacer;