
import './ImagePageLink.css'

function ImagePageLink(props)
{
    // if hover image null, replace with default
    let hvr_img_src = (props.hvr_img_src? props.hvr_img_src:props.img_src);
    return(
        <div className='wrapper rel-pos fill'>
            <img src={props.img_src} className='abs-pos fill clip'/> 
            <img src={hvr_img_src} className={getImgHoverClasses(props.hvr_img_src)}/>
            <div className='abs-pos panel'></div>
            <h1 className='abs-pos fill title'>
                {props.title} 
            </h1>
	    <h2 className='abs-pos fill title genre'>
	    	{props.genre}
	    </h2>
            <a className='abs-pos fill' href={props.link_url}></a>
        </div>
    );
}

export default ImagePageLink;

function getImgHoverClasses(filename)
{
    let classes = 'abs-pos fill clip hover-img';
    let fileExt = filename.split('.').pop();
    if(fileExt!='gif')
    {
        classes+=' zoom';
    }
    return classes;
}
