import { useState } from 'react';
import { useSpring, useTransition, animated } from 'react-spring'

import './BurgerIcon.css'

function BurgerIcon(props)
{

    const onClick=()=>{
        if(props.onClick!=null)
        {
            props.onClick();
        }
    }

    const spring = { mass: 10, tension: 1000, friction: 200 };

    const topTransition = useSpring({ 
        rotate: props.isOpen?45:0,y:props.isOpen?11:0,
        config:spring
    });

    const middleTransition = useSpring({ 
        opacity: props.isOpen?0:1,
        config:spring
    });

    const bottomTransition = useSpring({ 
        rotate: props.isOpen?-45:0,y:props.isOpen?-11:0,
        config:spring
    });

    return(
        <div className='burger-icon' onClick={onClick} style={props.style?props.style:{}}>
            <animated.div className='burger-line' style={topTransition}/>
            <animated.div className='burger-line' style={middleTransition}/>
            <animated.div className='burger-line' style={bottomTransition}/>
        </div>
    );

}

export default BurgerIcon;