import { useState } from 'react';

import ContactForm from "./ContactForm";
import Richtext from "./Richtext";


import "./ContactPage.css"

function ContactPage(props)
{
    const [submitted, setSubmitted] = useState(false);
    var onSubmit = ()=>{
        setSubmitted(true);
    }

    return(
        <div className="contact-page">
            <h1 className="profile-title">{props.contact.title}</h1>
            <div className="contact-body">
                <Richtext className="contact-intro" text={props.contact.intro}/>
                <ContactForm form_fields={props.contact.form_fields} onSubmit={onSubmit}/> 
                {props.contact.links.map((link,index)=>
                    <a key={index} href={link.link_url}>
                        <img className="contact-link" src={link.image.meta.download_url}/>
                    </a>
                )}
                {submitted? <Richtext className="contact-thanks" text={props.contact.thank_you_text}/>:null}
            </div>
        </div>
    );
}

export default ContactPage;