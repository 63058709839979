import { useState, useEffect, useRef } from 'react';
import { useSpring, useTransition, animated } from 'react-spring'
import { debounce } from "lodash"

import BurgerIcon from './BurgerIcon';

import "./PageNav.css"

function PageNav(props)
{
    const [{isIntro,isScrolling,burgerOpen}, setState] = useState({isIntro:true,isScrolling:false,burgerOpen:false});

    const slow = { mass: 10, tension: 100, friction: 100 };
    const fast = { mass: 10, tension: 700, friction: 100 };
    const sideMenuSpeed = { mass: 10, tension: 1000, friction: 200 };

    const introTransition = useSpring({ 
        to: { x:0, y:0,skew:25, opacity: 1 }, 
        from: { x:100,y:0,skew:25, opacity: 0 }, 
        onRest:()=>{setState({isIntro:false})},
        config:slow
    });

    const scrollTransition = useTransition(!isScrolling,{
        from:{y:-50,skew:25, opacity: 0},
        enter:{y:0,skew:25, opacity: 1},
        leave:{y:-50,skew:25, opacity: 0},
        config:fast
    });

    const burgerTransition = useTransition(burgerOpen,{
        from:{x:500,opacity: 0},
        enter:{x:-200, opacity: 1},
        leave:{x:500, opacity: 0},
        config:sideMenuSpeed
    });

    const debouncedScroll = useRef(
        debounce(async () => {
            setState({isScrolling:false});
        }, 500)
      ).current;

    var timer = null;
    var onScroll=()=>{
        debouncedScroll();
        setState({isScrolling:true,burgerOpen:false});
    }
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        debouncedScroll.cancel();
    },[debouncedScroll]);

    const onBurgerClick=()=>{
        setState({burgerOpen:!burgerOpen});
    };

    return(
        <div className="page-nav">
            {scrollTransition((style,item)=> 
            (item||isIntro)? 
                <animated.div className="nav-background" style={isIntro?introTransition:style}>
                    <BurgerIcon onClick={onBurgerClick} isOpen={burgerOpen}/>
                    {burgerTransition((style,item)=>
                    item?
                        <animated.ul className="top-links-mob" style={style}>
                            {props.links.map((link,index)=>
                                <li key={index} className={"nav-link"}>
                                    <a href={link.link_url}>
                                        {link.title}
                                    </a>
                                </li>
                            )}
                        </animated.ul>
                    :'')}
                    <ul className="top-links">
                        {props.links.map((link,index)=>
                            <li key={index} className={"nav-link"}>
                                <a href={link.link_url}>
                                    {link.title}
                                </a>
                            </li>
                        )}
                    </ul>
                </animated.div>
            : ''
            )}
        </div>
    );
}

export default PageNav;
