
import "./FormField.css"

function FormField(props)
{   
    var field = props.field;
    var id=props.id+"_field_"+field.id;
    var type = field.field_type;
    // detect phone number field that is just number
    if(type=='number' && field.label.toLowerCase().includes('phone'))
    {
        type = 'tel';
    }

    switch(type){
        case 'singleline':
            var input=<input className="form-input" id={id} type="text" name={field.label} placeholder={field.label} required={field.required}/>;
            break;
        case 'multiline':
            var input=<textarea className="form-input" id={id} name={field.label} placeholder={field.label} required={field.required}/>;
            break;
        default:
            var input=<input className="form-input" id={id} type={type} name={field.label} placeholder={field.label} required={field.required}/>;
            break;
    }

    return input;
    
}

export default FormField;