import {useEffect} from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';

import {GetPageAPI,LoadFonts} from './WagtailUtils'

import LoadingPage from './LoadingPage'
import PageNav from './PageNav';
import Richtext from './Richtext';
import VideoPlayer from './VideoPlayer'
import ExpandableImage from './ExpandableImage';

import './ProjectPage.css'


function ProjectPage()
{   
    const context = GetPageAPI('project.ProjectPage','title_image','links','nav_link','title','body_text','video','project_image')

    LoadFonts('Raleway');

    useEffect(() => {
	if(context !== null)
	{
            document.title = context.title+" | Ahmad Galea";
	}
    }, [context]);

    if( context === null)
    {
        return <LoadingPage/>
    }
   
    return(
            <div className='project-page'>
                <PageNav links={context.nav_link}/>
	    	<div className='project-page-header-wrapper'>
		    <div className='project-page-header-background'>
                	<h1 className='project-page-header-text'>{context.title}</h1>
	    	    </div>
	    	    <img src={context.title_image.meta.download_url} className='project-page-header-image'/>
	    	</div>
                <Richtext className={'project-page-intro'} text={context.body_text[0].text}/>
	    	<div className='project-link-wrapper'>
	    		{context.links.map((link,index)=>
				<a key={index} href={link.link_url}>
					<img className="project-link" src={link.image.meta.download_url}/>
				</a>
			)}
	    	</div>
                {context.video.map((video,index)=>
                    <div key={index} className='project-page-video'>
                        <div className='project-page-video-header'>{video.video.title}</div>
                        <VideoPlayer url={(new URL(video.video.file)).pathname} thumbnail={(new URL(video.video.thumbnail)).pathname}/>
                    </div>
                )}
                <Richtext className={'project-page-body'} text={context.body_text[1].text}/>
                <div className='project-page-image-block'>
                    {context.project_image.map((image,index)=>
                        <ExpandableImage key={index} side={!(index%2)?'left':'right'} caption={image.caption} image_url={image.image.meta.download_url}/>
                    )}
                </div>
            </div>
    );
}

export default ProjectPage;
