import {useEffect} from 'react';
import './LoadingPage.css'

function LoadingPage()
{ 
    useEffect(() => {
	    document.title = "Ahmad Galea | Loading...";
    }, []);
    return (
        <div className="loading-page">
	    <div style={{backgroundImage:`url("https://ahmadgalea.com/media/original_images/loading.webp")`}} className="loading-text"/>
        </div>
    );
}

export default LoadingPage;
