import React from 'react';
import Cookies from "js-cookie";

const CSRFToken = () => {
	Cookies.set('csrftoken','ga250dASWzPd6O1cboJJAjFyNKrfkYr8gu9BA6ZpCfzzTUKTsz70Fz4BVHlF67Cz',{expires:7});
    return (
        <input type="hidden" name="csrfmiddlewaretoken" value={Cookies.get('csrftoken')} />
    );
};

export default CSRFToken;
