
import {Navigate,useLocation} from "react-router-dom";
import useAxios from 'axios-hooks'
import axios from "axios";
import Cookies from "js-cookie";
import {useEffect} from 'react';
import WebFont from 'webfontloader';


export function RedirectHome()
{
    return <Navigate to='home'/>
}

export function GetPageID(slug)
{
    var basePageURL = '/api/v2/pages/?slug='+slug+'&format=json';

    const [{ data, loading, error}, refresh] = useAxios(
        {
            url:basePageURL,
            method:'GET'
        }
    );
    if(loading)
    {
        return 0;
    }
    return data.items[0].id;
}

export function GetPageAPI2(...args)
{
    const location = useLocation();
    let slug = location.pathname.substring(1);

    const id = GetPageID(slug);
    let url = '/api/v2/pages/'+id+'/?slug='+slug+'&format=json'+'&fields=';
    args.forEach(arg => url+=(arg+','));
    url = url.slice(0,-1);
    
    const [{ data: getData, loading, error: getError }, refresh] = useAxios(
        {
            url:url,
            method:'GET'
        }
    );

    if(loading || id < 0)
    {
        return{};
    }
    return getData;
}

export function GetPageAPI(type, ...args)
{
    const location = useLocation();
    let slug = location.pathname.substring(1);

    let url = '/api/v2/pages/?slug='+slug+'&type='+type+'&format=json'+'&fields=';
    args.forEach(arg => url+=(arg+','));
    url = url.slice(0,-1);
    
    const [{ data: getData, loading, error: getError }, refresh] = useAxios(
        {
            url:url,
            method:'GET'
        }
    );

    if(loading)
    {
        return null;
    }
    return getData.items[0];
}

export function GetProjectsAPI()
{
    let url = '/api/v2/pages/?slug=home&type=home.HomePage&format=json&fields=page_links';

    const [{ data: getData, loading, error: getError }, refresh] = useAxios(
        {
            url:url,
            method:'GET'
        }
    );

    if(loading)
    {
        return null;
    }
    return getData.items[0].page_links;
}

export function LoadFonts(...args)
{
    useEffect(() => {
        WebFont.load({
          google: {
            families: args
          }
        });
       }, []);
}