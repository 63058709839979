
import {useNavigate} from 'react-router-dom';

import CSRFToken from "./CSRFToken";
import FormField from "./FormField";

function ContactForm(props)
{
    const handleSubmit = event => {
        event.preventDefault();

        event.target.submit();
        event.target.reset();
        props.onSubmit();
      };
      
    var style={display:"none"}
    return(
        <div id={props.id?props.id:'contact'}>
            <form action="/form/" method="POST" onSubmit={handleSubmit} target="dummyframe">
                <CSRFToken/>
                {props.form_fields.map((field,index)=>
                    <FormField key={index} id="contact_form" className="contact-field" field={field}/>
                )}
                <input className="form-button" type="submit" value="Send"></input>
            </form>
            <iframe name="dummyframe" id="dummyframe" style={style}></iframe>
        </div>
    );
}

export default ContactForm;
