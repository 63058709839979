import {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route,Navigate} from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';

import LoadingPage from "./components/LoadingPage";
import HomePage from './components/HomePage'
import ProjectPage from './components/ProjectPage'
import MultiProjectPage from "./components/MultiProjectPage";

import logo from './logo.svg';
import './App.css';
import { GetProjectsAPI,RedirectHome,LoadFonts } from "./components/WagtailUtils";

function App() {

  LoadFonts('Raleway');
  
  useEffect(() => {
    document.title = "Ahmad Galea";
  }, []);

  const context = GetProjectsAPI()
  if( context === null)
  {
      return <LoadingPage/>
  }

  return (
    <ParallaxProvider>
      <Router>
        <Routes>
          <Route path="/" element={<RedirectHome/>}/>
          <Route path="/home" element={<HomePage/>}/>
          {context.map((page,index)=>{
            switch(page.description)
            {
              case "ProjectPage":
                return <Route key={index} path={page.link_url} element={<ProjectPage/>}/>
              case "MultiProjectPage":
                return <Route key={index} path={page.link_url} element={<MultiProjectPage/>}/>
              default:
                return ''
            }
          })}
        </Routes>
      </Router>
    </ParallaxProvider>
  );
}

export default App;
