import {useEffect} from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';

import {GetPageAPI,LoadFonts} from './WagtailUtils'

import LoadingPage from './LoadingPage'
import PageNav from './PageNav';
import Richtext from './Richtext';
import VideoPlayer from './VideoPlayer'
import ExpandableImage from './ExpandableImage';

import './ProjectPage.css'
import './MultiProjectPage.css'


function MultiProjectPage()
{   
    const context = GetPageAPI('project.ProjectPage','title_image','nav_link','title','body_text','video')

    LoadFonts('Raleway');

    useEffect(() => {
	if(context!==null)
	{
	   document.title = context.title+" | Ahmad Galea";
	}
    }, [context]);

    if( context === null)
    {
        return <LoadingPage/>
    }
    
    return(
            <div className='multi-project-page'>
                <PageNav links={context.nav_link}/>
	    	<div className='project-page-header-wrapper'>
	    		<div className='project-page-header-background'>
                		<h1 className='project-page-header-text'>{context.title}</h1>
	    		</div>
	    		<img src={context.title_image.meta.download_url} className='project-page-header-image'/>
	    	</div>
                <Richtext className={'project-page-intro'} text={context.body_text[0].text}/>
                {context.video.map((video,index)=>
                    <div className='multi-project-page-video-block'>
                        <div key={index} className={'multi-project-page-video mp-vid-'+(!(index%2)?'left':'right')}>
			    <div className={'multi-project-page-video-background mp-bg-'+(!(index%2)?'left':'right')}/>
                            <VideoPlayer url={(new URL(video.video.file)).pathname} thumbnail={(new URL(video.video.thumbnail)).pathname}/>
                        </div>
                        <div className={'multi-project-page-video-title mp-ttl-'+(!(index%2)?'left':'right')}>{video.video.title}</div>
                        <div className={'multi-project-page-video-caption mp-cp-'+(!(index%2)?'left':'right')}>{video.caption}</div>
                    </div>
                )}
            </div>
    );
}

export default MultiProjectPage;
