
import Richtext from './Richtext';

import './ProfilePage.css'

function ProfilePage(props)
{
    return(
        <div id={props.id?props.id:'profile'} className="profile-page">

            <h1 className='profile-title'>{props.profile.title}</h1>

            <div className='profile-body'>

                {props.profile.images.map(
                    (image,index)=>
                        <div key={index} className='profile-img-block'>
                            <div className='image-background'>
                                <img src={image.cropped_image.url} className='profile-img'/>
                            </div>
                            <h2 className='profile-caption'>{image.caption}</h2>
                        </div>
                    
                )}

                {props.profile.text_blocks.map(
                    (block,index) => <Richtext key={index} className={'profile-text-'+index} text={block.text}/>
                )}
                
                {props.profile.documents.map(
                    (document,index)=>
                    <a key={index} href={(new URL(document.file.meta.download_url)).pathname}>
                        <div className='profile-link'>
                            {document.title}
                        </div>
                    </a>
                )}

                {props.profile.links.map(
                    (link,index)=>
                    <a key={index} href={link.link_url}>
                        <div className='profile-link'>
                            {link.title}
                        </div>
                    </a>
                )}

            </div>
        </div>
    );
}

export default ProfilePage;
