
import { useState,useEffect } from 'react';
import { useSpring, useTransition, useSpringRef, animated } from 'react-spring'

import './FeatureHeader.css'

function FeatureHeader(props)
{
    const [{isIntro,groupIndex,imgIndex}, setState] = useState({
        isIntro:true,
        groupIndex:0,
        imgIndex:[...Array(props.header.header_group.length)].map(x=>0)
    });

    const slow = { mass: 10, tension: 100, friction: 100 };
    const fast = { mass: 10, tension: 100, friction: 100 };

    const introTransition = useSpring({ 
        to: { x:0, y:0,skew:25, opacity: 1 }, 
        from: { x:-100,y:0,skew:25, opacity: 0 }, 
        onRest:()=>{setState({isIntro:false})},
        config:fast
    });
    
    const transRef = useSpringRef();

    var transObject = {group:props.header.header_group[groupIndex],groupIndex:groupIndex,imgIndex:imgIndex};
    const groupTransition = useTransition(transObject,{
        from:{y:-500,skew:25, opacity: 0},
        enter:{y:0,skew:25, opacity: 1},
        leave:{y:500,skew:25, opacity: 0},
        delay:props.header.change_delay,
        config:slow,
        ref:transRef,
        onRest:()=>{
            // step group
            var gIndex;
           
	    // loop back
            (groupIndex===(props.header.header_group.length-1))?gIndex=0:gIndex=groupIndex+1;
            
	    //step image
            var iIndex = [...imgIndex];
            
            // loop back
            (iIndex[groupIndex]===(props.header.header_group[groupIndex].header_image.length-1))?
                iIndex[groupIndex]=0:
                iIndex[groupIndex]+=1;
	
            // set state
            setState({groupIndex:gIndex,imgIndex:[...iIndex]});
        }
    });

    useEffect(() => {
	    if(imgIndex===undefined || groupIndex===undefined)
	    {
		    return;
	    }
        transRef.start();
      },[groupIndex,imgIndex]);

    return(
        <div id={props.id?props.id:'header'} className='feature-header'>
            {groupTransition((style,item)=>
                item.group?
                <div className='header-wrapper'>
                    <animated.div className={(item.group.id%2?'right':'left')+'-header-text-container'} style={style}>
                        <h2 className={(item.group.id%2?'right':'left')+'-header-text'}>{item.group.title}</h2>
                    </animated.div>
                    <animated.img 
                        src={item.group.header_image[item.imgIndex[item.groupIndex]].image.meta.download_url} 
                        className={(item.group.id%2?'left':'right')+'-header-image'}
                        style={{opacity: style.opacity}}
                    /> 
                </div>
                :''
            )}
            <animated.div className="header-name-container" style={introTransition}>
                <h2 className="header-name">{props.header.name}</h2>
            </animated.div>
        </div>
    );
}

export default FeatureHeader;
