
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons'

import { useSpring, useTransition, animated } from 'react-spring'

import CrossIcon from './CrossIcon'

import "./ExpandableImage.css"

function ExpandableImage(props)
{
    return(
        <a href="#/" className="expandable-image-link">
            <div style={{backgroundImage:'url('+props.image_url+')'}} className="expandable-image-img">
            <div className={"expandable-image-caption-background caption-bg-"+props.side}/>
            <div className={"expandable-image-caption caption-"+props.side}>{props.caption}</div>
            </div>
            <CrossIcon/>
            <div className="expandable-image-panel"/>
            <FontAwesomeIcon className="expandable-image-magnifying-glass" icon={faMagnifyingGlassPlus}/>
        </a>
    );
}

export default ExpandableImage;