
import ReactPlayer from 'react-player'
import { useState, useEffect, useRef,useCallback } from 'react';

import { Slider, Direction,PlayerIcon } from 'react-player-controls'

import PlayCircle from './PlayCircle';

import './VideoPlayer.css'

function VideoPlayer(props)
{

  const playerRef = useRef();
  
    return(    
      <div className="video-player">
        <ReactPlayer 
          ref={playerRef}
          url={props.url} 
          className='react-player'
          width="100%"
          height="100%"
          controls
          light={props.thumbnail}
          playing
          playIcon={
              <div className='video-thumbnail'>
                  <PlayCircle width="10%" height="10%" className="video-play-circle"/>
              </div>
          }
        />
      </div>
    );
}

export default VideoPlayer;