
import { ParallaxBanner,useParallaxController } from 'react-scroll-parallax';
import {useLocation} from "react-router-dom";
import { useEffect } from 'react';

import {GetPageAPI,GetPageAPI2,LoadFonts} from './WagtailUtils'

import LoadingPage from './LoadingPage'
import PageNav from './PageNav';
import FeatureHeader from './FeatureHeader'
import ImageLinkArray from './ImageLinkArray'
import Spacer from './Spacer'
import ProfilePage from './ProfilePage'
import ContactPage from './ContactPage'
import ParallaxElement from './ParallaxElement';


import './HomePage.css'


function HomePage()
{   
    //const context = GetPageAPI2('title','page_links','background_images','profile')
    const context = GetPageAPI('home.HomePage','nav_link','header','page_links','background_images','profile','contact')

    LoadFonts('Raleway');

    const location = useLocation();
    const parallaxController = useParallaxController();

    useEffect(() => {
        parallaxController.update();
	document.title = 'Ahmad Galea';
    }, [location.pathname]);

    if( context === null)
    {
        return <LoadingPage/>
    }

    return(
        <div className='home-page'>
            <PageNav links={context.nav_link}/>
            <FeatureHeader id='home' header={context.header[0]}/>
            <ImageLinkArray id='links' page_links={context.page_links} links_per_page={context.links_per_page}/>
            <div className="spacer">
                <ParallaxBanner
                    layers={[{
                        image:context.background_images[0].image.meta.download_url,
                        speed:-70,
                        expanded: false,
                    }]}
                    style={{ backgroundSize:"cover",height:"130vh",position:"absolute",top:"-30vh"}}
                />
            </div>
            <ProfilePage id='profile' profile={context.profile[0]}/>
            <div className="spacer">
                <ParallaxBanner
                    layers={[{
                        image:context.background_images[1].image.meta.download_url,
                        speed:-70,
                        expanded: false,
                    }]}
                    style={{ backgroundSize:"cover",height:"130vh",position:"absolute",top:"-30vh"}}
                />
            </div>
            <ContactPage id='contact' contact={context.contact[0]}/>
            <Spacer height="10vh" img_src={context.background_images[1].image.meta.download_url}/>
        </div>
        
    );
}

export default HomePage;
